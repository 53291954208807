import "./work-experience-intro.styles.scss"


function WorkExperienceIntro() {
  return (
    <div className="work-experience-intro-container">
      <div className="intro-text">
        <h1> Work Experience </h1>
      </div>
    </div>
  );
}

export default WorkExperienceIntro
