import "./blog-intro.styles.scss"


function BlogIntro() {
  return (
    <div className="blog-intro-container">
      <div className="intro-text">
        <h1> My Blog </h1>
      </div>
    </div>
  );
}

export default BlogIntro
