import "./education-intro.styles.scss"


function EducationIntro() {
  return (
    <div className="education-intro-container">
      <div className="intro-text">
        <h1> My Education </h1>
      </div>
    </div>
  );
}

export default EducationIntro
